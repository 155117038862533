















import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Error extends Vue {

}
